.main-container-new-navbar {
  height: 90vh;
  padding: 10px;
  position: sticky;
  top: 10px;
}

.sub-container-new-navbar {
  background-color: var(--primary-color);
  height: 100%;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-logo-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.app-logo-icon {
  width: 130px;
}

.logo-icon {
  width: 35px;
}


.user-container-info {
  background-color: var(--tertiary-color);
  height: 50px;
  border-radius: 15px;
  margin-top: 30px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

}

.user-image-container {
  background-color: var(--primary-color);
  height: 100%;
  overflow: hidden;
  width: 50px;
  border-radius: 50%;

}

.user-image {
  width: 100%;
  height: 100%;
}

.user-name-text {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
}

.role-of-user {
  color: var(--grey-color);
  font-size: 12px;
  font-weight: 600;
}

.links-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
}

.certificate-generator-link {
  color: var(--white-color);
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  font-size: 14px;
  padding: 10px;
}

.certificate-generator-link-active {
  color: var(--white-color);
  cursor: pointer;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  font-size: 14px;
  background-color: var(--black-color);
}

.certificate-icon {
  width: 20px;
}


.navigation-bottom-feature {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.setting-page-link {
  cursor: pointer;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 10px;
}