.main-download-pop-up{
    /* width: 500px; */
    height: 500px;
    padding: 20px;
}

.certificate-celebration-heading{
    font-size: 20px;
    font-weight: 700;
}

.certificate-celebration-sub-heading{
    font-size: 18px;
    font-weight: 500;
}

.pop-up-preview{
    width: 400px;
}

.preview-container{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    column-gap: 50px;

}

.certificate-info{
    width: 100%;
    height: 250px;
    box-shadow: -1px 1px 6px rgb(149, 139, 139);
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.edit-button{
    border: none;
    height: 40px;
    width: 120px;
    border-radius: 10px;
}

.download-image{
        background-color: #132a52;
    color: #fff;
    border-radius: 10px;
    /* width: 160px; */
    height: 40px;
}

.download-pdf{
    /* background-color: #132a52; */
color: #fff;
border-radius: 10px;
/* width: 160px; */
height: 40px;
border: none;
color: black;
}