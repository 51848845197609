.forgot-password-page {
    font-family: "Poppins", sans-serif;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-image: url(/public/assets/Images/Frame.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.cgen-logo {
    position: absolute;
    display: flex;
    left: 190px;
    top: 60px;
}

.cgen-logo img {
    margin-top: 40px;
    height: 75px;
    width: 75px;
}

.cgen-logo h1 {
    margin-top: 58px;
    height: 39px;
    width: 120px;
}

.forgot-password-box {
    border: 1px solid rgb(192, 192, 192);
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: absolute;
    right: 10%;
}

.forgot-password-box h2 {
    margin-bottom: 0;
    font-size: 20px;
    text-align: left;
}

.forgot-password-form-input {
    margin-top: 20px;
    margin-bottom: 20px;

}

.forgot-password-form-input label {
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: normal;
}

.forgot-password-form-input input {
    width: 90%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 13px;
    background-color: #f9f9f9;
}

.forgot-password-form-input input:focus {
    border-color: #66afe9;
    outline: none;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.btn {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: none;
    background-color: #003366;
    color: white;
    font-size: 12px;
    cursor: pointer;
    margin-top: 10px;
}

.btn:hover {
    background-color: #0056b3;
}

.forgot-password-footer {
    margin-top: 40px;
    text-align: center;
    font-size: 10px;
    cursor: pointer;
}

.forgot-password-footer p {
    margin-top: 31px;
    margin-bottom: 8px;
    font-size: 12px;
    text-align: center;
    color: #8a8989;
}

.forgot-password-footer span {
    color: #000000;
    text-decoration: none;
    font-weight: bold;
}

.forgot-password-footer span:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-size: 13px;
    position: absolute;
    padding-top: 2px;
}