@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

@font-face {
  font-family: certificateFont;
  src: url(../assets/fonts/silentha.ttf);
}



 .main-header-certificate-manager{
  width: 100%;
  height: 80px;
  background-color: white;
 
 }

 .logo-recognify{
  height: 100%;
 }

 .certificate-main-container{
  background-color: #FCF1FF;
  height: 100vh;
  overflow: hidden;
 }

 .sub-container-cm-manager{
  width: 100%;
  height: 100%;

 }

 .certificate-preview-container{
  display: grid;
  grid-template-columns: 650px 1fr;
  height: 400px;
  column-gap: 30px;
  margin: 30px;
 }

 
 .certificate-preview{
  width: 100%;
  height: 100%;
 }

 .input-for-certificate{
  /* background-color: white; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

 }

 .input-for-certificate-sub-container{
  background-color: white;
  height: 100%;
  width: 90%;
  border-radius: 10px;
  padding: 20px;
  /* display: flex; */
  box-sizing: border-box;
  display: flex;
      flex-direction: column;
      justify-content: center;
 }

 .all-templates{
  position: relative;
 }

 .template-mapped{
  display: flex;
  overflow: hidden;
  column-gap: 40px;
  margin-top: 10px;
 }

 .image-templates{
  width: 160px;
  cursor: pointer;
 }
 .image-templates-selected{
  width: 160px;
  border: 1px solid rgb(112, 207, 112);
  cursor: pointer;
 }

 .left-shift{
  position: absolute;
  background-color: black;
  color: white;
  width: 20px;
  cursor: pointer;
  top: 40%;
  left: -15px;
  height: 20px;
  display: grid;
  place-items: center;
  font-size: 10px;
  border-radius: 10px;
 }

 .right-shift{
  position: absolute;
  background-color: black;
  color: white;
  width: 20px;
  cursor: pointer;
  top: 40%;
  right: -15px;
  height: 20px;
  display: grid;
  place-items: center;
  font-size: 10px;
  border-radius: 10px;
 }

 .main-heading-certificate{
  font-size: 22px;
  font-weight: 800;
 }


 .certificate-input-container{
  margin-top: 10px;

 }

 .input-data-label-container{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  position: relative;
 }

 .input-of-certificates{
  height: 30px;
  width: 100%;
  /* padding-right: 120px; */
  outline: none;
  background-color: #F3F3F3;
  border: none;
  border-radius: 5px;
  /* margin-top: 20px; */
 }

 .input-of-certificates-name{
  height: 30px;
  width: 100%;
  padding-right: 120px;
  outline: none;
  background-color: #F3F3F3;
  border: none;
  border-radius: 5px;
  /* margin-top: 20px; */
 }

 .issuer-info{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 20px;
 }
 
 .label-certificate-inputs{
  color: #6A6767;
  font-size: 14px;

 }

 .generate-button-container{
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
 }

 .generate-button{
  background-color: #2A87D4;
  height: 40px;
  width: 120px;
  /* border-radius: 10px; */
  border: none;
  font-size: 16px;
  color: white;
 }

 .add-student-container-data{
  width: 100%;
  position: relative;
 }

 .add-student-name{
  position: absolute;
  display: flex;
  align-items: center;
  top: 8%;
  margin-right: 10px;
  right: 0;
  color: #6A6767;
  font-size: 14px;
 }

 .add-container{
  cursor: pointer;
 }

 .count-of-added{
  width: 25px;
  display: grid;
  place-items: center;
  background-color: #58BA2E;
  height: 25px;
  margin-left: 10px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
 }

 .preview-image-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .certificate-data-info{
  position: absolute;
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* background-color: red; */
 }

 .description-line-first-cursive{
  margin-top: 10px;
  font-size: 20px;
  position: relative;
  text-align: center;
  padding: 0px 50px;
  font-weight: 500;
  font-family: certificateFont;
 }

 .description-line-first-normal{
  margin-top: 10px;
  font-size: 20px;
  position: relative;
  text-align: center;
  padding: 0px 50px;
  font-weight: 500;
  font-family: Georgia, 'Times New Roman', Times, serif;
 }

 .description-line-second{
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: Georgia, serif;
  text-align: center;
  padding : 0px 34px;
 }

 .issuer-info-container{
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 53px 100px;
 }

 .name-of-certification{
  margin-top: 6px;
  text-align: center;
  padding: 0px 80px;
  font-size: 27px;
  font-weight: 500;
  font-family: Georgia, serif;
 }

 .org-logo{
  height:  50px;

 }

 .issuer-name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

 .sign-icon{
  width: 40px;
 }


 .setting-to-change-font{
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
 }

 .setting-options{
  position: absolute;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgb(164, 155, 155);
  background-color: white;
  height: 120px;
  width: 100px;
  right: 0px;
  top: 50px;
  z-index: 3;
 }

 .setting-top-description{
  font-size: 12px;
 }

 .setting-options{
  padding: 10px
 }

 .selection-of-font-family{
  /* width: 50px; */
  font-size: 12px;
 }