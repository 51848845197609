.student-info-main{
    height: 400px;
    /* padding: 30px; */
    overflow: hidden;
}

.student-info-sub-container{
    height: 100%;
    width: 100%;
    overflow: scroll;
}
.table-main-container {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    /* margin: 20px 0; */
    font-family: Arial, sans-serif;
  }
  
  .table-main-container th {
    background-color: #4CAF50;
    color: white;
    padding: 12px 15px;
    text-align: left;
    font-size: 16px;
    position: sticky;
    top: 0px;
  }
  
  .table-body-student-info td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .table-body-student-info tr:hover {
    background-color: #f2f2f2;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }
  
  .table-body-student-info tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-body-student-info tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .input-of-certificates{
    cursor: pointer;
  }
  