.app-layout-application{
    display: grid;
    grid-template-columns:  1fr;
    transition: 1s all ease-in-out;
}

.app-layout-application-close{
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    transition: 1s all ease-in-out;
}

.navigation{
    position: relative;
}

.close-arrow-navigation{
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: grid;
    position: absolute;
    top: 60px;
    right: 0px;
    cursor: pointer;
    place-items: center;
    color: var(--white-color);
    background-color: var(--tertiary-color);
}